import React from 'react';
import {Column, Row} from "./layout";
import Tags from "./tags";
const moment = require('moment');

export default function PostMeta({ tags = [], date, date_modified, readingTime }) {
  return (
    <Row>
    <Column span={4}>
      <div style={{paddingTop: "0.25rem"}}>
        <time itemProp="datePublished" dateTime={date}>{moment(date).format("dddd, MMM DD YYYY")}</time>
        {date_modified && date_modified !== date &&
        <time itemProp="dateModified" dateTime={date_modified} />
        }
      </div>
    </Column>
    <Column span={8}>
      <Tags list={tags} />
    </Column>
  </Row>
  );
};
