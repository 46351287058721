import React from 'react';
import {Tag, Flask, Code} from 'styled-icons/fa-solid';
import {Keyboard} from 'styled-icons/fa-regular';
import {Linux} from 'styled-icons/fa-brands';
import {VideogameAsset} from 'styled-icons/material';
import styled from "styled-components";

const TagItem = styled.li`
  background: #828282;
  color: #fdfdfd;
  padding: 0.1rem 0.5rem 0.2rem 0.6rem;
  border-radius: 1em;
  display: inline;
  margin-left: 0.2rem;
  float: right;
  font-size: 1rem;
`;

export default function Tags({ list = [] }) {
  return (
    <ul style={{clear: "both", marginLeft: "0rem"}}>
      {list.map(tag => {
      const TagIcon = (() => {
          switch (tag.toLowerCase()) {
            case "emulation":
              return Keyboard;
            case "retro":
              return VideogameAsset;
            case "experiment":
              return Flask;
            case "code":
              return Code;
            case "linux":
              return Linux;
            default:
              return Tag;
          }
        })();

      return (
        <TagItem key={tag}>
          <TagIcon size="0.8rem" title={tag} style={{color: "white"}} />
          <span style={{paddingLeft: "0.3rem", fontVariant: "small-caps"}}>{tag}</span>
        </TagItem>
        );
      })}
    </ul>
  );
}
