import React from "react";
import {Layout, Row, Column} from "../components/layout";
import { Link, graphql } from "gatsby";
import Imgage from "gatsby-image";
import PostMeta from "../components/post-meta";
import styled from "styled-components";
import Helmet from "react-helmet";


const ThumbnailImage = styled(Imgage)`
  margin-right: 0.5rem;
  margin-top: 0.5rem;
  margin-left: 0rem;
  margin-bottom: 0.5rem;
`;

const PostPreview = styled.div`
  margin-bottom: 0rem;
`;

const PostDivider = styled.hr`
  margin-top: 1rem;
  margin-bottom: 1rem;
`;

const DraftTitle = styled.h2`
  background: repeating-linear-gradient(
    -35deg,
    transparent,
    transparent 10px,
    #f4d041 10px,
    #f4d041 20px
  );
  padding: 0.25rem;
`;

const PostListItem = props => {
  const {id, title, image, excerpt, tags, date, date_modified, slug, published, readingTime} = props;
  return (
    <PostPreview key={id}>
        {!published &&
        <DraftTitle><Link to={slug}>{title}</Link></DraftTitle>
        }
        {published &&
        <h2><Link to={slug}>{title}</Link></h2>}
      <PostMeta date={date} date_modified={date_modified} tags={tags} readingTime={readingTime} />
      <Row>
        <Column span={3}>
          {image &&
            <ThumbnailImage fluid={image.childImageSharp.fluid} />
          }
        </Column>
        <Column span={9}>
          <span>{excerpt}&nbsp;</span>
          <Link to={slug}>read more.</Link>
        </Column>
      </Row>
    </PostPreview>
  );
};

export default function Index({ data }) {
  const { edges: posts } = data.allMarkdownRemark;
  return (
    <Layout>
      <Helmet title='beardypig - Emulation, Reverse Engineering and Electronics' />
      {posts
        .filter(post => post.node.frontmatter.title.length > 0)
        .filter(post => post.node.frontmatter.published || process.env.BUILD_STAGE === `develop`)
        .map(({node: post}, index)  => {
          console.log(post.frontmatter.image);
          return (
            <>
            <PostListItem id={post.id}
                          slug={post.fields.slug}
                          readingTime={post.fields.readingTime.text}
                          title={post.frontmatter.title}
                          date={post.frontmatter.date}
                          date_modified={post.frontmatter.date_modified}
                          image={post.frontmatter.image}
                          tags={post.frontmatter.tags.split(" ")}
                          excerpt={post.excerpt}
                          published={post.frontmatter.published}
            />
            {index < posts.length-1 &&
              <PostDivider/>
            }
            </>
          );
        })}
    </Layout>
  );
}

export const pageQuery = graphql`
query IndexQuery {
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      limit: 1000
    ) {
      edges {
        node {
          excerpt(pruneLength: 250)
          id
          frontmatter {
            title
            published
            date
            date_modified
            tags
            repo
            image {
              childImageSharp {
                fluid(maxWidth: 200, maxHeight: 135) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          fields {
            slug
            readingTime {
              text
            }
          }
        }
      }
    }
  }
`;